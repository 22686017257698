<template>
  <div id="home-Right">
    <div id="home-right-box1" class="box-shadow"></div>

    <div id="home-right-box2" class="box-shadow"></div>
  </div>
</template>
<script>
import elementResizeDetectorMaker from 'element-resize-detector'
import { ALL_APPS_MENU } from '@/store/mutation-types'
export default {
  data() {
    return {
      chart1: null,
      chart2: null
    }
  },
  // 注册组件
  components: {},
  created() {},
  mounted() {
    // this.allMenus = Vue.ls.get(ALL_APPS_MENU)[0].menu
  },
  methods: {
    // 绘制图表
    drawEchart1() {
      if (this.chart1) {
        this.chart1.clear()
      } else {
        this.chart1 = this.$echarts.init(document.getElementById('echarts3'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('home-right-box1'), () => {
        this.chart1.resize()
      })

      let chartData = [
        {
          value: 53,
          name: '未带安全帽'
        },
        {
          value: 20,
          name: '模板支护违规'
        },
        {
          value: 18,
          name: '钢筋违规'
        },
        {
          value: 22,
          name: '混泥土浇筑'
        },
        {
          value: 16,
          name: '其他'
        }
      ]

      var colorList = ['#2573FF', '#FF5D2F', '#FFAB00', '#04A95B', '#1BC4E4']
      let sum = chartData.reduce((pre, next) => {
        return pre + next.value
      }, 0)

      let option = {
        tooltip: {
          trigger: 'item'
        },
        title: [
          {
            text: sum,
            x: '27%',
            y: '30%',
            textAlign: 'center',
            textStyle: {
              fontSize: '30',
              fontWeight: '500',
              color: '#212A33',
              textAlign: 'center'
            }
          },
          {
            text: '近七日事件类型\n统计',
            left: '27%',
            top: '52%',
            textAlign: 'center',
            textStyle: {
              fontSize: '12',
              fontWeight: '400',
              color: '#818C98',
              textAlign: 'center'
            }
          }
        ],
        radius: ['80%', '65%'],
        legend: {
          top: 'middle',
          // right: 'left',
          left: '55%',
          orient: 'vertical',
          align: 'left',
          itemWidth: 10, // 图例标记的宽度
          formatter: function(name) {
            let index = -1
            for (let i = 0; i < chartData.length; i++) {
              if (chartData[i].name === name) {
                index = i
                break
              }
            }
            if (index !== -1) {
              // const content = `${name.toString().padEnd(10," ")}(${chartData[index].value})`;
              const content = `${name.toString().padEnd(6)}(${chartData[index].value})`
              return content
            }
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['58%', '70%'],
            center: ['28%', '50%'], // 将圆心设置在容器的中心位置
            avoidLabelOverlap: false,
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            itemStyle: {
              normal: {
                color: function(params) {
                  return colorList[params.dataIndex] // 根据参数的索引返回对应颜色
                },
                borderWidth: 2,
                borderColor: '#fff',
                borderRadius: 4 // 正常情况下分隔线的圆角效果
              }
            },

            data: chartData
          }
        ]
      }

      option && this.chart1.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#home-Right {
  width: 100%;
  // height: 9rem;

  #home-right-box1 {
    width: 100%;
    height: 2.925rem;
    background: #ffffff;
    padding: 0.125rem;
    border-radius: 0.05rem;
    opacity: 1;
    margin-bottom: 0.21rem;
  }

  #home-right-box2 {
    width: 100%;
    padding: 0.125rem;
    height: 7.1625rem;
    background: #ffffff;
    border-radius: 0.11rem;
    opacity: 1;
    margin-bottom: 0.21rem;
  }
}

.box-shadow:hover {
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);
}
</style>
