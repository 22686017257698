<template>
    <div>
        <el-row>
            <el-col :span="16">
                <projectLeft />
            </el-col>
            <el-col :span="8">
                <projectRight />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import projectLeft from './project/projectLeft.vue'
import projectRight from './project/projectRight.vue'

export default {
    data() {
        return {
            key: ''
        }
    },
    components: {
        projectLeft,
        projectRight
    },
}
</script>

<style lang="scss" scoped>
</style>